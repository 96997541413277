import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { PLACEHOLDER_64_CASE_STUDY } from 'constants/placeholders';
import { Link, Image, Text } from 'components/atoms';
import AwwardSvg from 'images/awward.svg';

const WorkCard = ({ data, size, className, onClick }) => {
  const { title, wordpress_id, path, featured_media } = data;
  const {
    source_url,
    title: image_title,
    alt_text: image_alt,
    acf,
    media_details,
  } = featured_media || {};
  const { placeholder_base64, private_access } = acf || {};

  // TODO: Need it to come from the backend. Ask backend to do it dynamically.
  const hasAwward = wordpress_id === 715;
  const card = (
    <>
      <Image
        placeholder64={placeholder_base64}
        lazyLoading
        imageFit={'cover'}
        className={cn(`${className}__picture__link-image`)}
        src={source_url || PLACEHOLDER_64_CASE_STUDY}
        title={image_title}
        alt={image_alt}
        image={media_details}
      />
      {hasAwward && (
        <div className={`${className}__awward`}>
          <AwwardSvg />
        </div>
      )}

      <div className={cn(`${className}__title`)}>
        {size === 'small' && (
          <Text className={`${className}__title__text body-sm_bold`}>
            {title}
          </Text>
        )}
        {size === 'big' && (
          <Text tag="p" className={`${className}__title__text headline-4`}>
            {title}
          </Text>
        )}
      </div>
    </>
  );
  return (
    <div
      className={cn([className], { [`${className}_${size}`]: size })}
      data-index={wordpress_id}
    >
      <div
        className={cn(`${className}__picture`, {
          [`${className}_${size}__picture`]: size,
        })}
      >
        {private_access ? (
          <div
            className={cn(
              `${className}__picture__link`,
              `${className}__picture__link_private-access`
            )}
            onClick={onClick}
            onKeyDown={onClick}
            role="button"
            tabIndex="0"
          >
            {card}
          </div>
        ) : (
          <Link
            className={cn(`${className}__picture__link`)}
            href={path}
            onMouseDown={e => e.preventDefault()}
            onContextMenu={e => e.preventDefault()}
          >
            {card}
          </Link>
        )}
      </div>
    </div>
  );
};

WorkCard.defaultProps = {
  size: 'big',
  className: 'work-card',
};

WorkCard.propTypes = {
  data: PropTypes.object.isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
  // hasAwward: PropTypes.bool,
};

export default WorkCard;
