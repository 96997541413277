import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TweenLite } from 'gsap';
import debounce from 'lodash/debounce';
import memoize from 'lodash/memoize';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row, Column, Text } from 'components/atoms';
import WorkCard from 'components/molecules/WorkCard';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import IndustriesPopup from 'components/molecules/IndustriesPopup';

import { useAllCaseStudyWorkArray, useOptions } from 'graphql/hooks';

import { windowSize } from 'utils/window';
import { useWindowSize } from 'utils/windowSizeHook';

import { DEBOUNCE } from 'constants/debounce';

const findItemMarginBottom = memoize(item => {
  const itemMarginBottom = parseInt(
    getComputedStyle(item.querySelector('.work-card')).marginBottom
  );

  return itemMarginBottom;
});

const createColumns = (data, columnsNumber) => {
  const columns = [[], [], []];
  let columnsCount = 0;

  for (let i = 0; i < data.length; i++) {
    const work = data[i];

    columns[columnsCount].push(work);
    columnsCount += 1;

    if (columnsCount === columnsNumber) {
      columnsCount = 0;
    }
  }
  return columns;
};

const smoothScrollAnimation = columnsListRefs => {
  const { current: column } = columnsListRefs;
  const { scrollY } = window;

  const moveableCols = [column[0].current, column[column.length - 1].current];
  const scrollableDistance = document.body.scrollHeight - windowSize.height;
  let COUNT_ELEM = 0;
  const SMOOTH_RATIO = 0.65;

  if (windowSize.width > 728 && scrollableDistance > scrollY) {
    moveableCols.forEach(item => {
      const itemsLength = item.querySelectorAll('.work-card').length;

      if (itemsLength > COUNT_ELEM) {
        COUNT_ELEM = itemsLength;
      }
    });

    const offset =
      findItemMarginBottom(column[0].current) - scrollY / COUNT_ELEM;

    TweenLite.to(column[1].current, SMOOTH_RATIO, { y: offset / 3 });

    moveableCols.forEach(item => {
      TweenLite.to(item, SMOOTH_RATIO, { y: offset });
    });
  }
};

const WorksList = props => {
  const { className, breadcrumbsData, title, pageLink, homepage } = props || {};
  const width = useWindowSize();

  //same value on slice_array need to set in ClientsGrid
  const SLICE_ARRAY = useOptions().options.featured_works_number;
  const data = useAllCaseStudyWorkArray().slice(0, SLICE_ARRAY);

  const allWorks = useAllCaseStudyWorkArray();
  const homepageData = (homepage || []).map(featuredWork => {
    if (!featuredWork) return null;
    return allWorks.find(w => w.wordpress_id === featuredWork.wordpress_id);
  });

  const [value, setValue] = useState(3);
  const [isMobile, setIsMobile] = useState(width < 729 ? true : false);

  const columns = createColumns(
    homepage ? homepageData.filter(Boolean) : data,
    value
  );

  const columnsListRefs = useRef(columns.map(React.createRef));

  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isShowPopupMessage, setIsShowPopupMessage] = useState(false);
  const [caseStudyDataState, setCaseStudyDataState] = useState(null);

  if (width) {
    let newValue = windowSize.width > 1024 ? 3 : 2;
    if (value !== newValue) {
      setValue(newValue);
    }

    let newWidth = width < 729 ? true : false;
    if (isMobile !== newWidth) {
      setIsMobile(newWidth);
    }
  }

  const handleScroll = debounce(() => {
    if (homepage) return;
    smoothScrollAnimation(columnsListRefs);
  }, DEBOUNCE[10]);

  useEffect(() => {
    if (homepage) return;
    smoothScrollAnimation(columnsListRefs);

    window.addEventListener('scroll', handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleScroll]);

  return (
    <Section className={cn([className], homepage && 'works-list--homepage')}>
      <Container className={`${className}__container`}>
        {title && (
          <Row>
            <Column
              xl="4"
              lg="6"
              md="8"
              col="12"
              className={`${className}__title`}
            >
              <Text tag="h1" className={`${className}__title-text`}>
                {title}
              </Text>
            </Column>
          </Row>
        )}
        <Row>
          {columns.map((column, colId) => (
            <Column
              className={`${className}__column`}
              refName={columnsListRefs.current[colId]}
              lg="4"
              md="6"
              col="12"
              key={colId}
            >
              {column.map((workItem, workId) => {
                const {
                  title,
                  wordpress_id,
                  path,
                  featured_media,
                  acf,
                } = workItem;

                const wp_id = wordpress_id
                  ? wordpress_id
                  : featured_media.wordpress_id;

                const {
                  use_default_featured_image_on_mobile,
                  featured_image_mobile,
                } = acf || {};

                let content =
                  isMobile &&
                  !use_default_featured_image_on_mobile &&
                  featured_image_mobile
                    ? featured_image_mobile
                    : featured_media;

                let cardInfo = {
                  title: title,
                  wordpress_id: wp_id,
                  path: path,
                  featured_media: content,
                };

                return (
                  <WorkCard
                    size="big"
                    data={cardInfo}
                    key={workId}
                    onClick={() => {
                      setCaseStudyDataState(workItem);
                      setIsShowPopup(true);
                    }}
                  />
                );
              })}
            </Column>
          ))}
        </Row>
        {isShowPopup && (
          <IndustriesPopup
            link={pageLink}
            caseStudyData={caseStudyDataState}
            onClose={() => setIsShowPopup(false)}
            messagePopup={() => setIsShowPopupMessage(true)}
          />
        )}
        {isShowPopupMessage && (
          <IndustriesPopup
            className="industries-popup_message"
            message="Thank you, your request was successful!"
            onClose={() => setIsShowPopupMessage(false)}
          />
        )}
        {breadcrumbsData && (
          <Breadcrumbs {...breadcrumbsData} className={'breadcrumbs_works'} />
        )}
      </Container>
    </Section>
  );
};

WorksList.defaultProps = {
  className: 'works-list',
};

WorksList.propTypes = {
  data: PropTypes.array,
  breadcrumbsData: PropTypes.object,
  className: PropTypes.string.isRequired,
};

export default WorksList;
