import React, { useRef, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Text, Popup, Button, Input } from 'components/atoms';

import { useWindowSize } from 'utils/windowSizeHook';
import { getHost } from 'utils/api';

const sendForm = (name, title, company, email, link, caseStudy) => {
  let data = JSON.stringify({
    created_by: 8,
    'input_1': name,
    'input_2': title,
    'input_3': company,
    'input_4': email,
    'input_5': link,
    'input_6': caseStudy,
  });

  let xhr = new XMLHttpRequest();
  xhr.withCredentials = true;

  xhr.addEventListener('readystatechange', function() {
    if (this.readyState === 4) {
      // console.log(this.responseText);
    }
  });

  xhr.open('POST', `${getHost()}/wp-json/gf/v2/forms/1/submissions`);
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.setRequestHeader(
    'Authorization',
    'Basic Y2tfZGU0OTczOGRjMTI2Zjg5NDVjZmUwMDI3NDEyYzVkMjE1ZGI3NDUyOTpjc185ZGJhYWM2MDdhZGY0NTZjZmVmMDMzNjg1MDk4NzFjOTQ1MTYyODll'
  );

  xhr.send(data);
};

const validation = (
  nameValue,
  titleValue,
  companyValue,
  emailValue,
  setValidationState
) => {
  if (
    nameValue !== '' &&
    titleValue !== '' &&
    companyValue !== '' &&
    /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(emailValue)
  ) {
    setValidationState(true);
  } else {
    setValidationState(false);
  }
};

const emptyFields = (refArray, setEmptyFields) => {
  refArray.every(item => {
    if (item.current.value === '') {
      item.current.focus();
      setEmptyFields(true);
      return false;
    } else {
      setEmptyFields(false);
    }
    return true;
  });
};

const IndustriesPopup = ({
  className,
  onClose,
  message,
  messagePopup,
  link,
  caseStudyData,
}) => {
  const width = useWindowSize();
  const popupRef = useRef(null);
  const nameRef = useRef(null);
  const titleRef = useRef(null);
  const companyRef = useRef(null);
  const emailRef = useRef(null);
  const [validationState, setValidationState] = useState(false);
  const [emptyFieldsState, setEmptyFieldsState] = useState(false);
  const [isDesktop, setIsDesktop] = useState(width > 1024 ? true : false);

  if (width) {
    let newWidth = width > 1024 ? true : false;
    if (isDesktop !== newWidth) {
      setIsDesktop(newWidth);
    }
  }

  useLayoutEffect(() => {
    if (!isDesktop && message) {
      setTimeout(() => {
        if (popupRef.current) {
          popupRef.current.closePopup({ target: null }, true);
        }
      }, 4000);
    }
  });

  return (
    <Popup
      className={cn('industries-popup', [className])}
      onClose={onClose}
      ref={popupRef}
    >
      {message ? (
        <Text
          color="transparent-green"
          className="industries-popup__title headline-4"
        >
          {message}
        </Text>
      ) : (
        <>
          <Text
            color="transparent-green"
            className="industries-popup__title headline-4"
          >
            We can't always show you everything, but sometimes all you have to
            do is ask.
          </Text>
          <form
            noValidate
            className={cn('industries-popup__form')}
            onSubmit={e => {
              e.preventDefault();

              emptyFields(
                [nameRef, titleRef, companyRef, emailRef],
                setEmptyFieldsState
              );
              if (validationState && !emptyFieldsState) {
                sendForm(
                  nameRef.current.value,
                  titleRef.current.value,
                  companyRef.current.value,
                  emailRef.current.value,
                  link,
                  caseStudyData.post_title || caseStudyData.title
                );
                popupRef.current.closePopup(e, true);
                const timeout = setTimeout(() => messagePopup(), 10);
                return () => clearTimeout(timeout);
              }
            }}
            onChange={() => {
              validation(
                nameRef.current.value,
                titleRef.current.value,
                companyRef.current.value,
                emailRef.current.value,
                setValidationState
              );
              if (emptyFieldsState) {
                emptyFields(
                  [nameRef, titleRef, companyRef, emailRef],
                  setEmptyFieldsState
                );
              }
            }}
          >
            <Input
              className="industries-popup__input"
              placeholder="Your name"
              ref={nameRef}
              isRequired
            />
            <Input
              className="industries-popup__input"
              placeholder="Title"
              ref={titleRef}
              isRequired
            />
            <Input
              className="industries-popup__input"
              placeholder="Company name"
              ref={companyRef}
              isRequired
            />
            <Input
              className="industries-popup__input"
              placeholder="Email address"
              type="email"
              ref={emailRef}
              isRequired
            />
            <Button
              variant="inline"
              title="Request Access"
              className={cn('industries-popup__button', {
                'industries-popup__button_disabled': !validationState,
              })}
              type="submit"
            />
          </form>
        </>
      )}
    </Popup>
  );
};

IndustriesPopup.defaultProps = {
  className: '',
};

IndustriesPopup.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  message: PropTypes.string,
  messagePopup: PropTypes.func,
};

export default IndustriesPopup;
